import { Import } from 'lucide-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function LandingPage() {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-br from-purple-600 to-blue-500">
      <header className="px-4 lg:px-6 h-14 flex items-center">
        <a href="#" className="flex items-center justify-center text-white">
          <span className="text-2xl mr-2">🎓</span>
          <span className="sr-only">Bloom Academy</span>
        </a>
      </header>
      <main className="flex-1">
        <section className="w-full py-12 md:py-24 lg:py-32 xl:py-48 flex flex-col items-center justify-center">
          <div className="container px-4 md:px-6">
            <div className="flex flex-col items-center space-y-4 text-center">
              <div className="space-y-2">
                <h1 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl lg:text-6xl/none text-white">
                  Welcome to Bloom Academy
                </h1>
                <p className="mx-auto max-w-[700px] text-white md:text-xl">
                  Nurture your potential and grow with our innovative learning platform.
                </p>
              </div>
              <div className="space-x-4">
                <button onClick={() => navigate("/login")} className="px-4 py-2 bg-white text-purple-600 rounded-md hover:bg-opacity-90 transition-colors">
                  Login
                </button>
                <button  onClick={() => navigate("/register")} className="px-4 py-2 border border-white text-white rounded-md hover:bg-white hover:text-purple-600 transition-colors">
                  Register
                </button>
              </div>
            </div>
          </div>
        </section>
      </main>
      <footer className="flex flex-col gap-2 sm:flex-row py-6 w-full shrink-0 items-center px-4 md:px-6 border-t border-white/10">
        <p className="text-xs text-white">© 2023 Bloom Academy. All rights reserved.</p>
      </footer>
    </div>
  );
}