import axios from "axios";
const BASE_URL = process.env.REACT_APP_BASE_URL || "http://localhost:3000";

export default axios.create({
  baseURL: BASE_URL,
});

export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
});

export const postLogin = async (email, password) => {
  const LOGIN_URL = `${BASE_URL}/login`;

  try {
    const response = await axios.post(
      LOGIN_URL,
      { email, password },
      {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      }
    );
    console.log(response);
    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const postRegister = async (user) => {
  const REGISTER_URL = `${BASE_URL}/register`;

  try {
    const response = await axios.post(
      REGISTER_URL,
      JSON.stringify({
        name: user.name,
        email: user.email,
        password: user.password,
        assistantIdLaws: user.assistantIdLaws,
        assistantIdAuthors: user.assistantIdAuthors,
        company: user.company,
        role: user.role,
        plan: user.plan,
        isActive: user.isActive,
      }),
      {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      }
    );
    console.log(response);
    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};