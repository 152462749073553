import React from 'react';
import { Book, Calendar, Award, LogOut } from 'lucide-react';
import useLogout from "../../hooks/useLogout";
import useAuth from '../../hooks/useAuth';

const DashboardCard = ({ icon, title, value, linkText }) => (
  <div className="bg-white p-6 rounded-lg shadow-md flex flex-col items-center">
    {icon}
    <h3 className="mt-4 text-lg font-semibold text-purple-800">{title}</h3>
    <p className="text-3xl font-bold text-purple-600 my-2">{value}</p>
    <a href="#" className="text-blue-500 hover:underline">{linkText}</a>
  </div>
);

export default function UserDashboard() {
  const logout = useLogout();
  const PerfilContext = useAuth();

  const username = PerfilContext.auth.name;

  const logOut = async () => {
    console.log('Logging out...');
    await logout();
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-100 to-blue-100">
      <header className="bg-white shadow-md">
        <div className="container mx-auto px-4 py-4 flex justify-between items-center">
          <div className="flex items-center">
            <span className="text-3xl mr-2">🌸</span>
            <h1 className="text-xl font-bold text-purple-800">Bloom Academy</h1>
          </div>
          <nav className="flex items-center">
            <p className="mr-4 text-purple-800">Bienvenido, {username}</p>
            <button onClick={() => logOut() } className="flex items-center px-4 py-2 bg-purple-600 text-white rounded-md hover:bg-purple-700 transition-colors">
              <LogOut className="h-4 w-4 mr-2" />
              Cerrar sesión
            </button>
          </nav>
        </div>
      </header>

      <main className="container mx-auto px-4 py-8">
        <h2 className="text-2xl font-bold text-purple-800 mb-6">Tu Progreso</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <DashboardCard 
            icon={<Book className="h-8 w-8 text-purple-600" />}
            title="Cursos Activos"
            value="3"
            linkText="Ver mis cursos"
          />
          <DashboardCard 
            icon={<Calendar className="h-8 w-8 text-purple-600" />}
            title="Próxima Clase"
            value="Hoy, 15:00"
            linkText="Ver horario"
          />
          <DashboardCard 
            icon={<Award className="h-8 w-8 text-purple-600" />}
            title="Certificados"
            value="2"
            linkText="Ver certificados"
          />
        </div>

        <section className="mt-12">
          <h2 className="text-2xl font-bold text-purple-800 mb-4">Cursos Recomendados</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {['Introducción a la Programación', 'Diseño UX/UI', 'Marketing Digital'].map((course, index) => (
              <div key={index} className="bg-white border border-gray-200 rounded-md p-4 hover:shadow-md transition-shadow">
                <h3 className="text-lg font-semibold text-purple-700 mb-2">{course}</h3>
                <p className="text-gray-600 mb-4">Amplía tus habilidades con este curso diseñado para ti.</p>
                <a href="#" className="text-blue-500 hover:underline">Más información →</a>
              </div>
            ))}
          </div>
        </section>
      </main>

      <footer className="bg-purple-800 text-white py-4 mt-12">
        <div className="container mx-auto px-4 text-center">
          <p className="text-purple-200">&copy; 2023 Bloom Academy. Todos los derechos reservados.</p>
        </div>
      </footer>
    </div>
  );
}