import Login from "./components/app/Login";
import Home from "./components/app/Home";
import LandingPage from "./components/landingPage/LandingPage";
import Unauthorized from "./components/app/Unauthorized";
import Missing from "./components/app/Missing";
import RequireAuth from "./components/app/RequireAuth";
import PersistLogin from "./components/app/PersistLogin";
import Register from "./components/app/Register";

import { Routes, Route } from "react-router-dom";

const ROLES = {
  user: 100,
  admin: 200
};

function App() {
  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="login" element={<Login />} />
      <Route path="register" element={<Register />} />
      <Route path="unauthorized" element={<Unauthorized />} />
      <Route element={<PersistLogin />}>
        <Route
          element={<RequireAuth allowedRoles={[ROLES.user, ROLES.admin]} />}
        >
          <Route path="home" element={<Home />} />
        </Route>
      </Route>
      <Route path="*" element={<Missing />} />
    </Routes>
  );
}

export default App;
